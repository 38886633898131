@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
body {
	width: 100vw;
	margin: 0;
	font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	box-sizing: border-box;
	overflow: overlay;
}
body .MuiPaper-root {
	overflow: overlay;
}
body::-webkit-scrollbar,
.MuiPaper-root::-webkit-scrollbar,
.MuiDataGrid-virtualScroller::-webkit-scrollbar,
.cm-scroller::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	background: transparent;
}
body::-webkit-scrollbar-button,
.MuiPaper-root::-webkit-scrollbar-button,
.MuiDataGrid-virtualScroller::-webkit-scrollbar-button,
.cm-scroller::-webkit-scrollbar-button {
	display: none;
	background: transparent;
}
body::-webkit-scrollbar-thumb,
.MuiPaper-root::-webkit-scrollbar-thumb,
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb,
.cm-scroller::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.4);
}

body::-webkit-scrollbar-track-piece,
.MuiPaper-root::-webkit-scrollbar-track-piece,
.MuiDataGrid-virtualScroller::-webkit-scrollbar-track-piece,
.cm-scroller::-webkit-scrollbar-track-piece {
	background: transparent;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
