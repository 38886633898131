.u-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}

.u-flexDirectionRow {
	flex-direction: row !important;
}

.u-flexDirectionColumn {
	flex-direction: column !important;
}

.u-flex0 {
	-ms-flex: 0 0 auto !important;
	flex: 0 0 auto !important;
}

.u-flex1 {
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
}

.u-flexCenteredItems {
	display: -ms-flexbox !important;
	display: flex !important;
	justify-content: center !important;
	-webkit-box-align: center !important;
	-webkit-align-items: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}

.u-flexCenteredItemsY {
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-align: center !important;
	-webkit-align-items: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}

.u-justifyContentSpaceBetween {
	justify-content: space-between !important;
}
